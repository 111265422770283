import { ofType } from '@/helpers/utils/type'
import i18n from '@/locales'
import { IAccount } from '@/types/account.d'
import { IBoUser, IBoUserLight } from '@/types/auth.d'
import { ICardDetails, ICardLimitSetting, isVirtualCardType } from '@/types/card.d'
import { IParams } from '@/types/params.d'
import { EPermission } from '@/types/permission.d'
import { IUserDetails, IUserLight, IUserOAuthService } from '@/types/user.d'

const { t } = i18n.global

export enum EBankValidationRequestListState {
  Pending = 'pending',
  History = 'history',
  Mine = 'mine',
}

export enum EBankValidationRequestStatus {
  Canceled = 'canceled',
  ToValidate = 'to_validate',
  Validated = 'validated',
  Rejected = 'rejected'
}

export enum EBankValidationRequestMode {
  Client = 'client',
  Bank = 'bank',
}

export enum EBankValidationRequestType {
  CreateUser = 'create_user',
  CreateCard = 'create_card',
  UnlockCard = 'unlock_card',
  CreateUserCard = 'create_user_card',
  UpdateAccountLimit = 'update_account_limit',
  UpdateAccountTemporaryLimit = 'update_account_temporary_limit',
  UpdateCardLimit = 'update_card_limit',
  UpdateUser = 'update_user',
  CloseAccount = 'close_account',
  CreateAdmin = 'create_admin',
}

export interface IBankValidationRequestParams extends IParams {
  bo_user_uuid?: string
  mode?: EBankValidationRequestMode
  status?: EBankValidationRequestStatus[]
  type?: EBankValidationRequestType[]
  start?: string // YYYY-MM-DD
  end?: string // YYYY-MM-DD
}

export interface IBankValidationRequest {
  uuid: string
  type?: EBankValidationRequestType
  status: EBankValidationRequestStatus
  account: IAccount
  created_at: number
  created_by_bo_user: IBoUserLight | null
  created_by_o_auth_service: IUserOAuthService | null
  created_by_user: IUserLight | null
  mode: EBankValidationRequestMode
  permissions: EPermission[]
}

export interface IBankValidationRequestData {
  uuid: string
  date: number
  label: string
  account: string
  initiator: string
  status: EBankValidationRequestStatus
  clickable: boolean
}

export interface IBankValidationRequestDetails extends IBankValidationRequest {
  validated_at: number
  validated_by: IBoUser
  rejected_at: number
  rejected_by: IBoUser
  canceled_at: number
  canceled_by_bo_user: IBoUserLight | null
  canceled_by_o_auth_service: IUserOAuthService | null
  canceled_by_user: IUserLight | null
  comment: string
}

interface IBankValidationRequestCreateUser extends IBankValidationRequestDetails {
  type: EBankValidationRequestType.CreateUser
  details: {
    user: IUserDetails
  }
}
interface IBankValidationRequestCreateCard extends IBankValidationRequestDetails {
  type: EBankValidationRequestType.CreateCard
  details: {
    card: ICardDetails
  }
}

interface IBankValidationRequestUnlockCard extends IBankValidationRequestDetails {
  type: EBankValidationRequestType.UnlockCard
  details: {
    card: ICardDetails
  }
}

interface IBankValidationRequestCreateUserCard extends IBankValidationRequestDetails {
  type: EBankValidationRequestType.CreateUserCard
  details: {
    user: IUserDetails
    card: ICardDetails
  }
}

interface IBankValidationRequestUpdateAccountLimit extends IBankValidationRequestDetails {
  type: EBankValidationRequestType.UpdateAccountLimit
  details: {
    transaction_amount_monthly: {
      before: IAccountSettings
      after: IAccountSettings
    }
  }
}

interface IBankValidationRequestUpdateAccountTemporaryLimit extends IBankValidationRequestDetails {
  type: EBankValidationRequestType.UpdateAccountTemporaryLimit
  details: {
    before: {
      transaction_amount_temporary: IAccountTemporaryLimit
      transaction_amount_monthly_limit: number
    }
    after: {
      transaction_amount_temporary: IAccountTemporaryLimit
      transaction_amount_monthly_limit: number
    }
  }
}

interface IBankValidationRequestUpdateCardLimit extends IBankValidationRequestDetails {
  type: EBankValidationRequestType.UpdateCardLimit
  details: {
    card: ICardDetails,
    limits: {
      before: ICardLimitSetting
      after: ICardLimitSetting
    }
  }
}

interface IBankValidationRequestUpdateUser extends IBankValidationRequestDetails {
  type: EBankValidationRequestType.UpdateUser
  details: {
    user: {
      before: IUserDetails
      after: IUserDetails
    }
  }
}

interface IBankValidationRequestCloseAccount extends IBankValidationRequestDetails {
  type: EBankValidationRequestType.CloseAccount
}

interface IBankValidationRequestCreateAdmin extends IBankValidationRequestDetails {
  type: EBankValidationRequestType.CreateAdmin
  details: {
    bo_user: IBoUser
  }
}

export function isUserCreation (request: IBankValidationRequestDetails | null) {
  return ofType<IBankValidationRequestCreateUser>(request, request.type === EBankValidationRequestType.CreateUser)
}
export function isCardCreation (request: IBankValidationRequestDetails | null) {
  return ofType<IBankValidationRequestCreateCard>(request, request.type === EBankValidationRequestType.CreateCard)
}
export function isCardUnlock (request: IBankValidationRequestDetails | null) {
  return ofType<IBankValidationRequestUnlockCard>(request, request.type === EBankValidationRequestType.UnlockCard)
}
export function isCardHolderCreation (request: IBankValidationRequestDetails | null) {
  return ofType<IBankValidationRequestCreateUserCard>(request, request.type === EBankValidationRequestType.CreateUserCard)
}
export function isAccountLimitUpdate (request: IBankValidationRequestDetails | null) {
  return ofType<IBankValidationRequestUpdateAccountLimit>(request, request.type === EBankValidationRequestType.UpdateAccountLimit)
}
export function isAccountTemporaryLimitUpdate (request: IBankValidationRequestDetails | null) {
  return ofType<IBankValidationRequestUpdateAccountTemporaryLimit>(request, request.type === EBankValidationRequestType.UpdateAccountTemporaryLimit)
}
export function isCardLimitUpdate (request: IBankValidationRequestDetails | null) {
  return ofType<IBankValidationRequestUpdateCardLimit>(request, request.type === EBankValidationRequestType.UpdateCardLimit)
}
export function isUserUpdate (request: IBankValidationRequestDetails | null) {
  return ofType<IBankValidationRequestUpdateUser>(request, request.type === EBankValidationRequestType.UpdateUser)
}
export function isAccountClosing (request: IBankValidationRequestDetails | null) {
  return ofType<IBankValidationRequestCloseAccount>(request, request.type === EBankValidationRequestType.CloseAccount)
}
export function isBoUserCreate (request: IBankValidationRequestDetails | null) {
  return ofType<IBankValidationRequestCreateAdmin>(request, request.type === EBankValidationRequestType.CreateAdmin)
}

export function getBankValidationRequestLabel (request: IBankValidationRequest | IBankValidationRequestDetails | null) {
  if (!request) return ''

  switch (request.type) {
    case EBankValidationRequestType.CreateCard:
      if (request?.details?.card) {
        return isVirtualCardType(request?.details?.card.type) ? t('general.virtual_card_creation') : t('general.physical_card_order')
      }
      return t('general.card_order')
    case EBankValidationRequestType.CreateUser: return t('general.new_user_creation')
    case EBankValidationRequestType.UnlockCard: return t('general.card_unlock')
    case EBankValidationRequestType.CreateUserCard: return t('general.cardholder_user_creation')
    case EBankValidationRequestType.UpdateAccountLimit: return t('general.account_limit_update')
    case EBankValidationRequestType.UpdateAccountTemporaryLimit: return t('general.account_temporary_limit_update')
    case EBankValidationRequestType.UpdateCardLimit: return t('general.card_limit_update')
    case EBankValidationRequestType.UpdateUser: return t('general.user_information_update')
    case EBankValidationRequestType.CloseAccount: return t('general.account_closure')
    case EBankValidationRequestType.CreateAdmin: return t('general.admin_creation')
    default: return ''
  }
}

export function getBankValidationRequestModeLabel (request: IBankValidationRequest | IBankValidationRequestDetails) {
  switch (request.mode) {
    case EBankValidationRequestMode.Client: return t('general.client_request')
    case EBankValidationRequestMode.Bank: return t('general.bank_request')
    default: return ''
  }
}

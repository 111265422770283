<template>
  <core-modal active
              :size="ECoreModalSize.Small"
              :title="t('modal.reject_request.title')"
              :closable-button="false"
              data-cy="reject-bank-validation-request.modal-action.container">
    <template #body>
      <p class="pre-line">
        {{ modalContent }}
      </p>
      <core-textarea :model-value="comment"
                     :placeholder="t('placeholder.comment')"
                     :disabled="loading"
                     :min-height="110"
                     class="mt-2"
                     data-cy="reject-bank-validation-request.modal-action.comment"
                     @update:model-value="comment = $event" />
    </template>
    <template #footer>
      <core-button :theme="ECoreButtonTheme.Transparent"
                   :appearance="ECoreButtonAppearance.Filled"
                   :text="t('action.cancel')"
                   :disabled="loading"
                   @click="closeModal" />
      <core-button :text="t('action.confirm')"
                   :loading="loading"
                   :disabled="loading"
                   data-cy="reject-bank-validation-request.modal-action.button"
                   @click="onSubmit" />
    </template>
  </core-modal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  CoreButton,
  CoreModal,
  CoreTextarea,
  ECoreButtonAppearance,
  ECoreButtonTheme,
  ECoreModalSize
} from '@common/core-ui'

import { useAppStore } from '@/stores/app'
import { useBankValidationRequestStore } from '@/stores/bank-validation-request'
import { EBankValidationRequestType, IBankValidationRequestDetails } from '@/types/bank-validation-request.d'

const { t } = useI18n()

const props = defineProps<{
  bankValidationRequest: IBankValidationRequestDetails
}>()

const appStore = useAppStore()
const bankValidationRequestStore = useBankValidationRequestStore()

const loading = ref(false)
const comment = ref<string>('')

const modalContent = computed(() => {
  // TODO
  switch (props.bankValidationRequest.type) {
    case EBankValidationRequestType.CreateUser:
      return t('modal.reject_request.content.create_user', {
        user_name: ''
      })
    case EBankValidationRequestType.CreateCard:
      return t('modal.reject_request.content.order_card_physical', {
        user_name: ''
      })
    case EBankValidationRequestType.UnlockCard:
      return t('modal.reject_request.content.unlock_card', {
        user_name: ''
      })
    case EBankValidationRequestType.CreateUserCard:
      return t('modal.reject_request.content.create_cardholder_user', {
        user_name: ''
      })
    case EBankValidationRequestType.UpdateAccountLimit:
      return t('modal.reject_request.content.update_account_limit', {
        account_label: `${props.bankValidationRequest.account.label} (${props.bankValidationRequest.account.company.name})`
      })
    case EBankValidationRequestType.UpdateAccountTemporaryLimit:
      return t('modal.reject_request.content.update_account_temporary_limit', {
        account_label: `${props.bankValidationRequest.account.label} (${props.bankValidationRequest.account.company.name})`
      })
    case EBankValidationRequestType.UpdateCardLimit:
      return t('modal.reject_request.content.update_card_limit', {
        user_name: ''
      })
    case EBankValidationRequestType.UpdateUser:
      return t('modal.reject_request.content.update_user', {
        user_name: ''
      })
    case EBankValidationRequestType.CloseAccount:
      return t('modal.reject_request.content.close_account', {
        account_label: `${props.bankValidationRequest.account.label} (${props.bankValidationRequest.account.company.name})`
      })
    case EBankValidationRequestType.CreateAdmin:
      return t('modal.reject_request.content.create_admin', {
        user_name: ''
      })
    default: return ''
  }
})

function closeModal () {
  appStore.closeModal()
}

async function onSubmit () {
  loading.value = true
  const response = await bankValidationRequestStore.rejectBankValidationRequest(props.bankValidationRequest.uuid, comment.value)
  if (response) {
    closeModal()
  }
  loading.value = false
}
</script>
